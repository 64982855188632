<template>
  <div v-if="!isLoading" id="basic-info">
    <header-service
      :service="service"
      currentTab="Informações Básicas"
      :devicesPictures="devicesPictures"
      :isSaving="!isLoading && isSaving"
      @resetFiles="resetFiles"
      @saving="save()"
    ></header-service>
    <div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>
      <div class="col-lg-9 col-md-8">
        <u-portlet title="Informações Básicas">
          <loading-service :enabled="isLoading"></loading-service>
          <div ref="form" v-if="!isLoading">
            <div class="form-group m-form__group row">
              <div class="col-lg-6">
                <label>Nome do Serviço</label>
                <input
                  type="text"
                  :disabled="inputDisable"
                  v-model="service.name"
                  class="form-control m-input"
                />
              </div>
              <div class="col-lg-6">
                <label>Nome Extrato/Fatura</label>
                <input
                  type="text"
                  :disabled="inputDisable"
                  v-model="service.invoice_name"
                  class="form-control m-input"
                />
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-6">
                <label>Provedor Integração</label>
                <select
                  :disabled="inputDisable"
                  class="form-control m-input m-input--square"
                  v-model="service.company_integration_id"
                >
                  <option :value="null">
                    Selecione o provedor/parceiro deste serviço.
                  </option>
                  <option
                    v-if="companyIntegration && companyIntegration.length"
                    v-for="(company, integrationIndex) in companyIntegration"
                    :key="integrationIndex"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </option>
                </select>
              </div>
              <div class="col-lg-6">
                <div v-if="$store.state.auth.user.profile <= 2">
                  <label>Provedor Conteudo</label>
                  <select
                    class="form-control m-input m-input--square"
                    :disabled="inputDisable"
                    v-model="service.company_content_id"
                  >
                    <option :value="null">
                      Selecione o provedor/parceiro deste serviço.
                    </option>
                    <option
                      v-if="companyContent && companyContent.length"
                      v-for="(company, contentIndex) in companyContent"
                      :key="contentIndex"
                      :value="company.id"
                    >
                      {{ company.name }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <label>Provedor Conteudo</label>
                  <input
                    disabled
                    type="text"
                    v-model="service.companyPartnerContent.name"
                    class="form-control m-input"
                  />
                </div>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-6">
                <label>Responsável Claro</label>
                <select
                  :disabled="inputDisable"
                  class="form-control m-input m-input--square"
                  v-model="service.responsible_id"
                >
                  <option :value="null">
                    Selecione um responsável para este serviço.
                  </option>
                  <option
                    v-if="responsibles && responsibles.length"
                    v-for="(responsible, responsibleIndex) in responsibles"
                    :key="responsibleIndex"
                    :value="responsible.id"
                  >
                    {{ `${responsible.first_name} ${responsible.last_name}` }}
                  </option>
                </select>
              </div>
              <div class="col-lg-6">
                <label></label>
                <div
                  v-if="$store.state.auth.user.profile <= 2"
                  class="switch mt-3"
                >
                  <switches
                    :disabled="inputDisable"
                    v-model="service.elegible"
                    color="green"
                  ></switches>
                  <div class="switch-label">Serviço elegível para campanha</div>
                </div>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-6">
                <label>LA</label>
                <input
                  :disabled="inputDisable"
                  type="text"
                  v-model="service.LA"
                  class="form-control m-input"
                />
              </div>
              <div v-if="$store.state.auth.user.profile <= 2" class="col-lg-3">
                <label>Monetização (share)</label>
                <select
                  :disabled="inputDisable"
                  class="form-control m-input m-input--square"
                  v-model="service.monetization"
                >
                  <option
                    v-if="percent"
                    v-for="(percent, percentIndex) in percents"
                    :key="percentIndex"
                    :value="percent"
                  >
                    {{ `${percent}%` }}
                  </option>
                </select>
              </div>
              <div class="col-lg-3">
                <label></label>
                <div class="switch mt-3">
                  <switches
                    :disabled="inputDisable"
                    v-model="service.microbiling"
                    color="green"
                  ></switches>
                  <div class="switch-label">Microbiling</div>
                </div>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-6">
                <label>Vigência do Serviço</label>
                <div class="row">
                  <div class="col-12">
                    <div
                      class="input-daterange input-group"
                      id="m_datepicker_5"
                    >
                      <date-picker
                        :disabled="inputDisable"
                        v-model="service.vigency_start"
                        :config="datePickerOptions"
                      ></date-picker>
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-calendar"></i>
                        </span>
                      </div>
                      <date-picker
                        :disabled="service.undetermined > 0 || inputDisable"
                        v-model="service.vigency_end"
                        :config="datePickerOptions"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <label></label>
                <div class="switch mt-3">
                  <switches
                    :disabled="inputDisable"
                    v-model="service.undetermined"
                    color="green"
                  ></switches>
                  <div class="switch-label">Indefinido</div>
                </div>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <div class="col-lg-3">
                <label>Categoria</label>
                <select
                  :disabled="inputDisable"
                  class="form-control m-input m-input--square"
                  v-model="service.category_id"
                >
                  <option :value="null">
                    Selecione uma categoria para este serviço.
                  </option>
                  <option
                    v-if="categories && categories.length"
                    v-for="(category, categoryIndex) in categories"
                    :key="categoryIndex"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <!-- credencial e entitlement -->

              <div class="col-lg-4">
                <label>Possui credencial no SSO Claro?</label>
                <div class="m-radio-inline mt-3">
                  <label class="m-radio m-radio--success">
                    <input
                      type="radio"
                      name="sso"
                      v-model="service.sso"
                      :value="1"
                    />
                    Sim
                    <span></span>
                  </label>
                  <label class="m-radio m-radio--focus">
                    <input
                      type="radio"
                      name="sso"
                      @change="service.entitlement = null"
                      v-model="service.sso"
                      :value="0"
                    />
                    Não
                    <span></span>
                  </label>
                </div>
              </div>

              <div class="col-lg-3">
                <label>Cancelamento Agendado</label>
                <div class="m-radio-inline mt-3">
                  <label class="m-radio m-radio--success">
                    <input
                      type="radio"
                      name="scheduled_cancellation"
                      v-model="service.scheduled_cancelation"
                      :value="1"
                    />
                    Sim
                    <span></span>
                  </label>
                  <label class="m-radio m-radio--focus">
                    <input
                      type="radio"
                      name="scheduled_cancellation"
                      v-model="service.scheduled_cancelation"
                      :value="0"
                    />
                    Não
                    <span></span>
                  </label>
                </div>
              </div>

              <div v-if="service.sso" class="col-lg-5">
                <label>direito/entitlement?</label>
                <input
                  type="text"
                  :disabled="inputDisable"
                  class="form-control m-input"
                  v-model="service.entitlement"
                />
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <label>Termos e Condições Serviço (URL)</label>
                <input
                  :disabled="inputDisable"
                  type="text"
                  v-model="service.terms"
                  class="form-control m-input"
                />
              </div>
            </div>
            
            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <label>Termos e Condições Claro (URL)</label>
                <input
                  :disabled="inputDisable"
                  type="text"
                  v-model="service.terms_claro"
                  class="form-control m-input"
                />
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <label>Website do serviço (URL)</label>
                <input
                  :disabled="inputDisable"
                  type="text"
                  v-model="service.website"
                  class="form-control m-input"
                />
              </div>
            </div>
            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <label for="description">Descrição detalhada do serviço</label>
                <textarea
                  placeholder="Apresente seu produto detalhando como se estivesse contando para alguém que viu pela primeira vez e que não conhece a Claro e seus serviços. Potencialize a introdução, os pontos chaves de atração, o que o diferencia e os motivos de adquirir este produto/serviço. Quais questões eu respondo a necessidade do cliente. Sempre coloque o cliente em primeiro lugar. O que eu estou resolvendo para ele?"
                  id="description"
                  :disabled="inputDisable"
                  class="form-control m-input"
                  v-model="service.description"
                  title="Apresente seu produto detalhando como se estivesse contando para alguém que viu pela primeira vez e que não conhece a Claro e seus serviços. Potencialize a introdução, os pontos chaves de atração, o que o diferencia e os motivos de adquirir este produto/serviço. Quais questões eu respondo a necessidade do cliente. Sempre coloque o cliente em primeiro lugar. O que eu estou resolvendo para ele?"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <label
                  >Benefícios e diferenciais (listar os principais
                  destaques)</label
                >
                <textarea
                  title="listar os principais destaques"
                  :disabled="inputDisable"
                  class="form-control m-input"
                  v-model="service.objective"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <label title="Telefone, e-mail, site, etc">FAQ</label>
                <QuillEditor v-model="service" :propety="'faq'" />
              </div>
            </div>

            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <RegionsSelect v-model="service" />
              </div>
            </div>

            <div class="form-group m-form__group row">
              <div class="col-lg-12">
                <label>Público alvo</label>
                <textarea
                  :disabled="inputDisable"
                  class="form-control m-input"
                  v-model="service.target_audience"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div
              class="form-group m-form__group row mb-3 no-gutters"
              id="image-service"
            >
              <div class="col-lg-12">
                <label>Imagem do Serviço</label>
              </div>

              <template v-for="(config, device) in devices">
                <div class="col-lg-4" :key="device">
                  <label v-text="`${config.label} (${config.size})`"></label>
                  <DisplayImageUpload
                    @changeImage="devicesPictures[device] = $event"
                    @deleteImage="deleteImageDevice(device, $event)"
                    width="300"
                    height="250"
                    :file="service[device]"
                    :type="device"
                  />
                </div>
              </template>

              <div class="col-lg-12">
                <div class="alert alert-dark mt-4" role="alert">
                  Fazer upload da imagem no formato PNG de acordo com o tamanho
                  do dipositivo com até 5mb.
                </div>
              </div>
            </div>
          </div>
        </u-portlet>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue-bootstrap-datetimepicker";
import Switches from "vue-switches";
import HeaderService from "../components/HeaderService";
import MenuService from "../components/MenuService";
import LoadingService from "../components/LoadingService.vue";
import ServiceService from "../services/ServiceService";
import UPortlet from "../../../components/UPortlet";

export default {
  name: "basic_info",
  components: {
    UPortlet,
    DatePicker,
    Switches,
    HeaderService,
    MenuService,
    LoadingService,
    DatePicker,
    RegionsSelect: () => import("../components/RegionsSelect.vue"),
    DisplayImageUpload: () =>
      import("../components/file/DisplayImageUpload.vue"),
    QuillEditor: () => import("@/components/QuillEditor.vue"),
  },
  data() {
    return {
      devices: {
        mobile: {
          size: "304x200px",
          label: "Mobile",
        },

        tablet: {
          size: "712x280px",
          label: "Tablet",
        },

        desktop: {
          size: "1200x280px",
          label: "Desktop",
        },
      },

      isLoading: true,
      isSaving: false,
      service: {},
      devicesPictures: {
        desktop: null,
        mobile: null,
        tablet: null,
      },
      serviceService: new ServiceService(),
      providersIntegration: {},
      providersContent: {},
      responsibles: {},
      inputDisable: !this.$authorization.can("service-update"),
      datePickerOptions: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      percents: [],
      categories: {},
    };
  },
  watch: {
    "$store.state.service.lock": function (newValue, oldValue) {
      this.lockForm(newValue);
    },
  },
  mounted() {
    this.fillPercent();
    this.getService();
  },
  methods: {
    getService() {
      let promise = this.serviceService
        .getService(this.$route.params.id)
        .then((response) => {
          this.service = response.data;
          this.convertDatePtBrService();
          this.companyIntegration = response.data.companyIntegration;
          this.companyContent = response.data.companyContent;
          this.responsibles = response.data.responsibles;
          this.categories = response.data.categories;
          this.service.regions = response.data.regions.map((item) => item.id);
          this.isLoading = false;
          this.preventEditForm();
        })
        .catch((error) => {
          console.log(error.response.data);
        });

      let vm = this;

      $.when(promise).done(function () {
        vm.preventEditForm();
      });
    },

    resetFiles() {
      Object.keys(this.devicesPictures).forEach((key) => {
        this.devicesPictures[key] = null;
      });
    },

    async save() {
      this.isSaving = true;
      this.lockForm(true);

      await this.serviceService
        .save(this.service)
        .then(async (response) => {
          await this.lockForm(false);
          this.isSaving = false;
          swal("Sucesso!", "Serviço atualizado com sucesso.", "success");

          this.getService();
        })
        .catch((error) => {
          this.lockForm(false);
          this.isSaving = false;

          swal("Ops!", this.$helper.formRequestErrorsMessage(error), "error");
        });
    },
    convertDatePtBrService() {
      if (this.service.vigency_start) {
        this.service.vigency_start = moment(this.service.vigency_start).format(
          "DD/MM/YYYY"
        );
      }

      if (this.service.vigency_end) {
        this.service.vigency_end = moment(this.service.vigency_end).format(
          "DD/MM/YYYY"
        );
      }
    },

    fillPercent() {
      for (let i = 1; i <= 100; i++) {
        this.percents.push(i);
      }
    },
    lockForm(isLocked) {
      const form = $(this.$refs.form);

      if (isLocked) {
        form.find("input, textarea, select").prop("disabled", true);
      } else {
        form.find("input, textarea, select").prop("disabled", false);
      }
    },

    deleteImageDevice(device, value) {
      this.devicesPictures[device] = value;
      this.service[device] = value;
      this.service[`${device}_image`] = value;
    },

    preventEditForm() {
      if (this.service.status_register !== "updating") {
        this.$store.commit("setLock", true);
        this.lockForm(true);
      }
    },
  },
};
</script>
